<template>
  <div class="layout-home">
    <div class="first-section">
      <img src="./images/momo_home_bg_1.png?t=20220713" alt="" />
      <a
        class="cta-button"
        href="https://www.momoshop.com.tw/category/MgrpCategory.jsp?m_code=2700400108&sourcePageType=4&osm=t04&utm_source=BD_010933&utm_medium=LINE-richmenu&scm_activity=202206240231ngevtn65"
        target="_blank"
      >
        <img src="./images/momo_home_cta_btn.png?t=20220623" alt="" />
      </a>
    </div>
    <img src="./images/momo_home_bg_2.png?t=20220713" alt="" />
    <img src="./images/momo_home_bg_3.png?t=20220713" alt="" />
    <img src="./images/momo_home_bg_4.png?t=20220713" alt="" />
    <img src="./images/momo_home_bg_5.png?t=20220713" alt="" />
    <img src="./images/momo_home_bg_6.png?t=20220713" alt="" />
    <img src="./images/momo_home_bg_7.png?t=20220713" alt="" />
    <img src="./images/momo_home_bg_8.png?t=20220713" alt="" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  setFavicon,
  setMeta,
  setTitle,
} from "../../../store/modules/liff/helpers";

export default {
  async created() {
    const org_code = this.$route.params.org_code;
    await this.$store.dispatch("liffHuggies/setOrgCode", { orgCode: org_code });
    await this.$store.dispatch("liffHuggies/fetchLiffInfo");

    setTitle(this.liffInfo.liff_og_title);
    setFavicon(this.liffInfo.liff_icon_url);
    setMeta("og:site_title", this.liffInfo.liff_og_title);
    setMeta("og:description", this.liffInfo.liff_og_description);
    setMeta("og:image", this.liffInfo.liff_og_image);
  },

  computed: {
    ...mapState("liffHuggies", { liffInfo: (state) => state.liffInfo }),
  },
};
</script>

<style lang="scss">
.layout-home {
  max-width: 576px;
  margin: auto;
  img {
    width: 100%;
  }

  .first-section {
    position: relative;
    .cta-button {
      position: absolute;
      bottom: 0;
      width: 35%;
      left: 50%;
      transform: translateX(-50%);
      bottom: 28%;
      cursor: pointer;
    }
  }
}
</style>
